<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { DateTime } from 'luxon'
import useAuthStore from '~/stores/auth'

const { t } = useI18n()
const { user } = storeToRefs(useAuthStore())

const greetings = computed(() => {
  const hour = Number(DateTime.local().toFormat('H'))
  if (hour < 4) return t('home.greetings.night')
  if (hour < 12) return t('home.greetings.morning')
  if (hour < 18) return t('home.greetings.afternoon')
  return t('home.greetings.evening')
})
</script>

<template>
  <p
    v-if="user"
    class="fluid-text-base"
  >
    {{ greetings }},<br>
    <span class="font-semibold fluid-text-lg">{{ user.fullName }}!</span>
  </p>
</template>
