import type { SubscriptionDetails } from '~/models/Subscription'
import type { LicenseDetails } from '~/models/License'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import arrayUtils from '~/utils/arrayUtils'
import useLicenseStore from '~/stores/license'
import { StaticSubscriptions, SubscriptionType } from '~/models/Subscription'
import useTime from '~/composables/useTime'

export function useSubscription() {
  const { uniqueBy, unique } = arrayUtils()
  const { isoStringInFuture } = useTime()

  const {
    loadLicenses,
    getLatestEndDateByEan,
  } = useLicenseStore()

  const {
    validLicenses
  } = storeToRefs(useLicenseStore())

  const isLoading = ref(false)

  const setSubscriptionsDetails = async () => {
    isLoading.value = true
    try {
      await loadLicenses()
      const subscriptionSet = [...Object.values(StaticSubscriptions).map(sub => sub.ean)]
      const licencesSet: LicenseDetails[] = [...validLicenses.value]
      const subscriptionLicenses = licencesSet
        .filter(license => subscriptionSet.includes(license.ean))
        .filter(license => isoStringInFuture(license.endDate))
        .filter(uniqueBy('ean'))

      const activeSubscriptions = subscriptionLicenses.map(license => {
        return StaticSubscriptions.find((subscription) => subscription.ean === license.ean)
      })

      const subscriptionGrades = activeSubscriptions
        .map((subscription) => subscription?.grades)
        .filter(unique)
        .sort()

      const shownSubscriptions: SubscriptionDetails[] = []
      subscriptionGrades.map((grades) => {
        const premiumSubscription = activeSubscriptions
          .find((subscription) => subscription?.type === SubscriptionType.Premium && subscription?.grades === grades)
        const plusSubscription = activeSubscriptions
          .find((subscription) => subscription?.type === SubscriptionType.Plus && subscription?.grades === grades)
        const basicSubscription = activeSubscriptions
          .find((subscription) => subscription?.type === SubscriptionType.Basic && subscription?.grades === grades)
        const individualSubscription = activeSubscriptions
          .find((subscription) => subscription?.type === SubscriptionType.Individual && subscription?.grades === grades)
        const trialSubscription = activeSubscriptions
          .find((subscription) => subscription?.type === SubscriptionType.Trial && subscription?.grades === grades)

        if (!premiumSubscription && !plusSubscription && !basicSubscription && !individualSubscription && !trialSubscription) return
        shownSubscriptions.push(premiumSubscription || plusSubscription || basicSubscription || individualSubscription || trialSubscription)
      })

      shownSubscriptions.forEach((subscription) => subscription.endDate = getLatestEndDateByEan(subscription.ean))

      return shownSubscriptions.sort((a, b) => (a.grades > b.grades ? 1 : -1))
    } finally {
      isLoading.value = false
    }
  }

  return {
    setSubscriptionsDetails,
    isLoading,
  }
}

export default useSubscription
